<template>
  <div class="c-app"> 
    <div v-if="!has_raffles">{{no_raffles_label}}</div>
    <CWrapper v-if="raffle">
      <TheHeader :c1="raffle.color_1" :c2="raffle.color_2" @anchor="anchor"/>
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid class="p-0">
            <Raffle :raffle="raffle" />
            <CRow id="precios" ref="precios" class="home-section-one">
              <CCol class="home-title" sm="12" :style='"background-color: "+raffle.color_2'>
                <h3>PRECIOS</h3>
              </CCol>
              <CCol v-for="(quantityOption,i) in quantityOptions" :key="'cantidad-'+i" class="home-subtitle" sm="3" :style='"color: "+raffle.color_2'>
                <h4>{{quantityOption.label}} POR ${{quantityOption.value}}</h4>
              </CCol>
              <CCol sm="12" style="height: 20px;"></CCol>
            </CRow>
            <CRow id="tickets" ref="tickets" class="home-section-one">
              <CCol class="home-title" sm="12" :style='"background-color: "+raffle.color_2'>
                <h3>SELECCIONA TUS BOLETOS</h3>
              </CCol>
              <CCol sm="12" style="height: 20px;"></CCol>
            </CRow>
            <CRow v-if="raffle.ticket_options > 1">
              <CCol class="home-subtitle" sm="12" :style='"color: red"'>
                <CRow>
                  <CCol sm="3"></CCol>
                  <CCol sm="6" class="text-center">
                    <p class="text-center">
                      Para esta rifa, cada boleto tiene <b>{{raffle.ticket_options}} NÚMEROS</b> que participan para ganar el premio.
                    </p>
                  </CCol>
                  <CCol sm="3"></CCol>        
                </CRow>     
              </CCol>
            </CRow>
            <CRow v-if="selection.length > 0">
              <CCol class="home-subtitle" sm="12" :style='"color: "+raffle.color_2'>
                <h3>BOLETOS SELECCIONADOS ({{selection.length}})</h3>
                <CRow>
                  <CCol sm="3"></CCol>
                  <CCol sm="6" class="text-center">
                  <p class="text-center">
                    Puedes descartar boletos haciendo click en los que deseas quitar de tu lista
                  </p>
                  <template v-for="ticket in tickets">
                    <button :key="'boton-ticket-1'+ticket.id" type="button" class="btn btn-outline-dark" style="margin: 1px; font-size: 12px;" v-if="selection.includes(ticket.id)" @click="unsetTicket(ticket.id)">{{ticket.numbers}}</button>
                  </template>                    
                  </CCol>
                  <CCol sm="3"></CCol>        
                  <CCol sm="12" style="height: 20px;"></CCol>
                </CRow>     
              </CCol>
            </CRow>
            <CRow v-show="selection.length == 0">
              <CCol class="home-subtitle" sm="12" :style='"color: "+raffle.color_2'>
                <button type="button" class="btn btn-info" style="margin: 2px;" @click="openRandomModal()">MAQUINITA DE LA SUERTE</button> 
              </CCol>
              <CCol sm="12" style="height: 20px;"></CCol>
            </CRow>
            <CRow>
              <CCol class="home-subtitle" sm="12" :style='"color: "+raffle.color_2'>
                <h3>BOLETOS DISPONIBLES</h3>
              <CRow>
              <CCol sm="3"></CCol>
              <CCol sm="6" class="text-center">
                <p class="text-center">
                  Puedes seleccionar desde 1 hasta 100 boletos, o si lo prefieres puedes elegirlos al azar y dejar todo a la suerte
                </p>
                <template v-for="ticket in tickets">
                  <button :key="'boton-ticket'+ticket.id" type="button" class="btn btn-outline-dark" style="margin: 1px; font-size: 12px;" v-if="!selection.includes(ticket.id)" @click="setTicket(ticket.id)">{{ticket.numbers}}</button>
                </template>                
              </CCol>
              <CCol sm="3"></CCol>        
              <CCol sm="12" style="height: 20px;"></CCol>
              </CRow>     
              </CCol>
            </CRow>
            <Sections :raffle="raffle" />
          </CContainer>
        </main>
      </div>
      <TheFooter :c1="raffle.color_1" :c2="raffle.color_2" @anchor="anchor"/>
      <SelectionModal ref="selectionModal" :tickets="selection.length" :c1="raffle.color_1" :c2="raffle.color_2" @selectTickets="selectTickets"></SelectionModal>
      <RandomTicketModal ref="randomTicketModal" :c1="raffle.color_1" :c2="raffle.color_2" @randomTickets="setRandomTickets"></RandomTicketModal>
      <button type="button" class="btn btn-dark" @click="openSelectionModal()" style="position:fixed;bottom:10px;right:10px;margin:0;" v-if="selection.length > 0">APARTAR {{selection.length > 1 ? selection.length+" BOLETOS" : selection.length+' BOLETO'}}</button>
      <button type="button" class="btn btn-danger" @click="clearTickets()" style="position:fixed;bottom:10px;right:190px;margin:0;" v-if="selection.length > 0">LIMPIAR</button>
    </CWrapper>
    <loading :active="visible" :can-cancel="true"></loading>
    <CToaster placement="top-end">
      <template v-for="(toast, key) in toasts">
        <CToast :color="toast.color" v-bind="toast" :key="'toast' + key" :show="true">
          {{toast.content}}
        </CToast>
      </template>
    </CToaster>
  </div>
</template>

<script>
import TheHeader from '../../containers/TheHeaderLanding'
import TheFooter from '../../containers/TheFooterLanding'
import SelectionModal from '../../components/application/SelectionModal.vue'
import RandomTicketModal from '../../components/application/RandomTicketModal.vue'
import Sections from '../../components/application/Sections'
import Raffle from '../../components/application/Raffle'
import Vue from 'vue';
import Loading from 'vue-loading-overlay/dist/vue-loading.js';
import 'vue-loading-overlay/dist/vue-loading.css';
import ws from '../../services/raffles';
import store from '../../store'

export default {
  name: 'Tickets',
  components: {
    TheHeader,
    TheFooter,
    Loading,
    SelectionModal,
    RandomTicketModal,
    Sections,
    Raffle
  },
  data() {
      return {
          has_raffles: true,
          no_raffles_label: "",
          raffle: false,
          selection: [],
          tickets: [],
          color_1: false,
          color_2: false,
          quantityOptions: []
      }
  },
  mounted: async function() {
    this.loading();
    this.code = await this.$route.params.code;

    let response = false;
    if(this.code === undefined){
      response = await ws.getMainRaffle();  
    }
    else{
      response = await ws.getRaffle(this.code);  
    }

    if(response.type == "success"){
        this.raffle = response.data;
        this.quantityOptions = [
          {label: "1 Boleto", value: 1 * this.raffle.ticket_price},
          {label: "2 Boleto", value: 2 * this.raffle.ticket_price},
          {label: "3 Boleto", value: 3 * this.raffle.ticket_price},
          {label: "4 Boleto", value: 4 * this.raffle.ticket_price},
          {label: "5 Boleto", value: 5 * this.raffle.ticket_price},            
          {label: "10 Boleto", value: 10 * this.raffle.ticket_price},
          {label: "20 Boleto", value: 20 * this.raffle.ticket_price},
          {label: "25 Boleto", value: 25 * this.raffle.ticket_price},
          {label: "30 Boleto", value: 30 * this.raffle.ticket_price},
          {label: "40 Boleto", value: 40 * this.raffle.ticket_price},
          {label: "50 Boleto", value: 50 * this.raffle.ticket_price},
          {label: "100 Boleto", value: 100 * this.raffle.ticket_price}
        ];
    }
    else{
        this.no_raffles_label = response.message;
        this.has_raffles = false;
    }

    let response2 = await ws.getMainTickets(); 

    if(response2.type == "success"){
        this.tickets = response2.data;
    }
    
    this.loaded();
  }, 
  computed: {
    toasts () {
      return store.state.toasts;
    },
    visible() {
      return store.state.isLoading;
    }
  },
  methods: {
    anchor(anchor_id){
      let anchor = false;
      switch(anchor_id){
        case 'nosotros': case 'preguntas': case 'contacto': case 'garantia': case 'inicio': {
          this.$router.push({path: (this.code !== undefined ? '/'+this.code : '')+'/', query: {anchor: anchor_id}});
          return;
        }
        case 'payments': {
          this.$router.push({path: (this.code !== undefined ? '/'+this.code : '')+'/pagos'})
          return;
        }
        case 'terms': {
          this.$router.push({path: (this.code !== undefined ? '/'+this.code : '')+'/aviso-de-privacidad'})
          return;
        }
      }
    },
    clearTickets(){
      this.selection = [];
    },
    setTicket(ticket_id){
      this.selection.push(ticket_id);
    },
    setRandomTickets(tickets){
      tickets.forEach(element => {
        this.selection.push(element.id);  
      });      
    },
    unsetTicket(ticket_id){
      var index = this.selection.indexOf(ticket_id);
      if (index !== -1) {
        this.selection.splice(index, 1);
      }
    },
    openSelectionModal(){
      this.$refs.selectionModal.showModal();
    },
    openRandomModal(){
      this.$refs.randomTicketModal.showModal(this.tickets);
    },
    async selectTickets(data){
      this.loading();
      
      let params = {
        name: data.name,
        whatsapp: data.whatsapp,
        state: data.state,
        ticket_ids: this.selection
      }

      let response = await ws.selectTickets(this.raffle.code, params);
      
      if(response.type == "success"){
        let whatsapp_url = response.data;        
        window.location.href = whatsapp_url;

        this.selection = [];
        this.loaded();

        return;
      }
      else{
        alert(response.message);
        this.showToast(response.type, response.message);
        this.loaded();
      }
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
<style scoped>
.home-title{
    display: flex;
    width: 100%;
    color: #fff;
    height: 80px;
    text-align: center;
    font-family: "Lato", sans-serif;
    font-size: 54px;
    text-shadow: 1px 3px 5px #000;
    align-items: center;
    justify-content: center;
}
h1, h3{
  font-weight: bolder;
}
.home-subtitle{
  text-align: center;
  margin-top: 10px;
  text-shadow: 1px 3px 5px #AAA;
  font-family: "Lato", sans-serif;
}
.home-subtitle a{
  text-align: center !important;
  margin-top: 10px !important;
  text-shadow: 1px 3px 5px #AAA !important;
  font-family: "Lato", sans-serif !important;
}
.home-icon a{
  display: flex;
    flex-direction: column;
    position: relative;
    text-align: center;
    line-height: normal;
    height: auto;
    min-height: 20px;
    min-width: 20px;
    overflow: hidden;
    margin: 0 5px 0 -1px;
    padding: 0 24px;
    cursor: pointer;
    pointer-events: auto;
}
.c-body{
  font-family: "Lato", sans-serif !important;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.row{
  margin:0px;
}
</style>
