<template>
    <CModal
      title="BOLETOS DE LA SUERTE"
      color="dark"
      :show.sync="selectionModal"
    >             
      <CSelect
        label="BOLETOS A GENERAR"
        :value.sync="quantity"
        :options="quantityOptions"
      />
          <br />
          <CRow v-show="randomStarted && randomAnimation">
            <CCol class="home-subtitle" sm="12">
              <h2>GENERANDO BOLETOS</h2>
              <span style="width: 100%; text-align: center;"><img src="/dado.gif"></span>
            </CCol>
          </CRow>
          <CRow v-show="randomStarted && !randomAnimation">
            <CCol class="home-subtitle" sm="12">
              <template v-if="randomTickets.length > 0" >
                <h2>BOLETOS GENERADOS</h2>
                <span v-for="ticket in randomTickets" :key="'boton-ticket-random-'+ticket.id" class="btn btn-outline-dark" style="margin: 2px;">{{ticket.numbers}}</span>
                <br /><br />
                <button type="button" class="btn btn-outline-dark home-subtitle" style="margin: 2px; font-size: 30px;" @click="getRandomTickets()">
                  <span>VOLVER A GENERAR</span>
                </button>
              </template>
            </CCol>
          </CRow>
          <CRow v-show="!randomStarted">
            <CCol class="home-subtitle" sm="12">
              <button type="button" class="btn btn-outline-dark home-subtitle" style="margin: 2px; font-size: 30px;" @click="getRandomTickets()">
                <span>HAZ CLICK AQUI PARA GENERAR TUS BOLETOS</span>
              </button>
            </CCol>
          </CRow> 
          <br /> 
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton v-show="randomTickets.length > 0" @click="setRandomTickets" color="success">¡LOS QUIERO!</CButton>
        </template>
    </CModal>
</template>

<script>

export default {
  name: 'SelectionModal',
  props: {
    c1: {
      type: String,
      default: '#000000'
    },
    c2: {
      type: String,
      default: '#F29200'
    },
  },
  data () {
    return {
      selectionModal: false,
      title: "Apartar Boletos",
      color: "info",
      submitted: false,
      quantity: 1,
      defaultTickets: [],
      randomTickets: [],
      randomStarted: false,
      randomAnimation: false,
      quantityOptions: [
        {label: "1 Boleto", value: 1},
        {label: "2 Boletos", value: 2},
        {label: "3 Boletos", value: 3},
        {label: "4 Boletos", value: 4},
        {label: "5 Boletos", value: 5},
        {label: "6 Boletos", value: 6},
        {label: "7 Boletos", value: 7},
        {label: "8 Boletos", value: 8},
        {label: "9 Boletos", value: 9},
        {label: "10 Boletos", value: 10},
        {label: "20 Boletos", value: 20},
        {label: "30 Boletos", value: 30},
        {label: "40 Boletos", value: 40},
        {label: "50 Boletos", value: 50},
        {label: "100 Boletos", value: 100},
        {label: "250 Boletos", value: 250},
      ]
    }
  },
  mounted: async function() {
      
  },
  methods: {
    showModal (tickets) {
      //Si a fuerzas tenemos que generar un nuevo array, si no arregla el principal
      tickets.forEach(element => {
        this.defaultTickets.push(element)
      });
      this.selectionModal = true;
      this.randomStarted = false;
    },
    closeModal () {
      this.selectionModal = false;
      this.randomTickets = [];
      this.randomStarted = false;
    },
    getRandomTickets () {
      this.randomStarted = true;
      this.randomAnimation = true;

      let _this = this;

      setTimeout(function(){
          _this.randomTickets = [];
          _this.defaultTickets.sort(function(){ return Math.random() - 0.5 });
          for (let index = 0; index <= (_this.quantity-1); index++) {
            _this.randomTickets.push(_this.defaultTickets[index]);
          }

          _this.randomAnimation = false;
      }, 2500);      
    },
    setRandomTickets () {
      this.$emit("randomTickets",this.randomTickets);
      this.selectionModal = false;
      this.randomTickets = [];
      this.randomStarted = false;
    },

  },
  computed: {

  },
}
</script>
<style>
  .home-subtitle{
    text-align: center;
    margin-top: 10px;
    text-shadow: 1px 3px 5px #AAA;
    font-family: "Lato", sans-serif;
    width: 100%;
  }
</style>