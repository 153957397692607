<template>
    <CModal
      title="APARTAR BOLETOS"
      color="dark"
      :show.sync="selectionModal"
    >   
        <CForm>
          <div class="home-subtitle" :style="'color: '+c2"><h1>INGRESA TUS DATOS</h1></div>
          <div class="home-subtitle" :style="'color: '+c2"><h4>{{tickets > 1 ? tickets+" BOLETOS SELECCIONADOS" :  tickets+" BOLETO SELECCIONADO"}} </h4></div>
          <br />
          <CInput
              label="Whatsapp:"
              :lazy="false"
              :value.sync="$v.form.whatsapp.$model"
              :isValid="checkIfValid('whatsapp')"
              type="number"
              placeholder=""
              autocomplete="given-whatsapp"
              invalidFeedback="Debes ingresar los 10 dígitos numéricos de tu número de whatsapp."
              required
              @change="checkWhatsapp()"
          />
          <CInput
              label="Nombre Completo:"
              :lazy="false"
              :value.sync="$v.form.name.$model"
              :isValid="checkIfValid('name')"
              placeholder=""
              autocomplete="given-name"
              invalidFeedback="Debes ingresar tu nombre completo."
              required
          />
          <CSelect
              label="Estado"
              :value.sync="$v.form.state.$model"
              :options="statesOptions"
            />
          <br /> 
          <div class="home-subtitle" :style="'color: '+c2"><h5>¡Al finalizar serás redirigido a <b>whatsapp</b> para enviar la información de tu boleto!</h5></div>
          <br /> 
          <div class="home-subtitle" :style="'color: red'"><h5>Tu boleto sólo dura 8 HORAS apartado</h5></div>
        </CForm>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="selectTickets" color="success">APARTAR</CButton>
        </template>
    </CModal>
</template>

<script>

import { validationMixin } from "vuelidate"
import { required, integer, minLength, maxLength, email, sameAs, helpers, requiredIf } from "vuelidate/lib/validators"
import ws from '../../services/raffles';

export default {
  name: 'SelectionModal',
  props: {
    c1: {
      type: String,
      default: '#000000'
    },
    c2: {
      type: String,
      default: '#F29200'
    },
    tickets: 0,
  },
  data () {
    return {
      selectionModal: false,
      title: "Apartar Boletos",
      color: "info",
      form: this.getEmptyForm(),
      submitted: false,
      statesOptions: [
        {label: "Estados Unidos (USA)", value: "USA"},
        {label: "Aguascalientes", value: "Aguascalientes"},
        {label: "Baja California", value: "Baja California"},
        {label: "Baja California Sur", value: "Baja California Sur"},
        {label: "Campeche", value: "Campeche"},
        {label: "Chiapas", value: "Chiapas"},
        {label: "Chihuahua", value: "Chihuahua"},
        {label: "CDMX", value: "Ciudad de México"},
        {label: "Coahuila", value: "Coahuila"},
        {label: "Colima", value: "Colima"},
        {label: "Durango", value: "Durango"},
        {label: "Estado de México", value: "Estado de México"},
        {label: "Guanajuato", value: "Guanajuato"},
        {label: "Guerrero", value: "Guerrero"},
        {label: "Hidalgo", value: "Hidalgo"},
        {label: "Jalisco", value: "Jalisco"},
        {label: "Michoacán", value: "Michoacán"},
        {label: "Morelos", value: "Morelos"},
        {label: "Nayarit", value: "Nayarit"},
        {label: "Nuevo León", value: "Nuevo León"},
        {label: "Oaxaca", value: "Oaxaca"},
        {label: "Puebla", value: "Puebla"},
        {label: "Querétaro", value: "Querétaro"},
        {label: "Quintana Roo", value: "Quintana Roo"},
        {label: "San Luis Potosí", value: "San Luis Potosí"},
        {label: "Sinaloa", value: "Sinaloa"},
        {label: "Sonora", value: "Sonora"},
        {label: "Tabasco", value: "Tabasco"},
        {label: "Tamaulipas", value: "Tamaulipas"},
        {label: "Tlaxcala", value: "Tlaxcala"},
        {label: "Veracruz", value: "Veracruz"},
        {label: "Yucatán", value: "Yucatán"},
        {label: "Zacatecas", value: "Zacatecas"}
      ]
    }
  },
  mounted: async function() {
      
  },
  methods: {
    showModal () {
      this.selectionModal = true;

      this.submitted = false;

      this.form = this.getEmptyForm();
    },
    closeModal () {
      this.selectionModal = false;

      this.form = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },
    selectTickets () {
      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;

        this.$emit("selectTickets", this.form);
      }
    },
    async checkWhatsapp () {
      let response = await ws.checkWhatsapp(this.form.whatsapp);  

      if(response.type == "success"){
        this.form.name = response.data.name;
      }
      else{
        this.form.name = "";
      }
    },
    getEmptyForm () {
      return {
          name: '',
          state: 'Jalisco',
          whatsapp: ''
      }
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid || field.$model === '')
    },
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(5)
      },
      whatsapp: {
        required,
        integer,
        minLength: minLength(10),
        maxLength: maxLength(10)
      },
      state: {
        required
      }
    }
  },
}
</script>
<style>
  .home-subtitle{
    text-align: center;
    margin-top: 10px;
    text-shadow: 1px 3px 5px #AAA;
    font-family: "Lato", sans-serif;
    width: 100%;
  }
</style>